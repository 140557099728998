import logo from './logo.svg';
import './App.css';
import { StyleClass } from 'primereact/styleclass';
import './root.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import {
    useEventListener,
    useMountEffect,
    useUnmountEffect,
} from "primereact/hooks";
import React, { useContext, useEffect, useRef, useState } from "react";
import { classNames } from "primereact/utils";
import { Button } from 'primereact/button';
import { Ripple } from 'primereact/ripple';
import { Divider } from 'primereact/divider';
import { PrimeReactContext } from "primereact/api";
import { Link, useNavigate } from 'react-router-dom';
import { Sidebar } from 'primereact/sidebar';
import logo_with_text from './images/logo-with-text.PNG';
import img1 from './images/iStock-1432806513 copy-min.jpg'
import img2 from './images/iStock-1442394358 copy-min.jpg'
import img3 from './images/iStock-1333702977 copy-min.jpg'
import img4 from './images/iStock-1441869990 copy-min.jpg'


export const Landing = () => {
    const [isHidden, setIsHidden] = useState(false);
    const navigate = useNavigate();
    const [visibleTop, setVisibleTop] = useState(false);
    const [visible, setVisible] = useState(false);
    const toggleMenuItemClick = () => {
        setIsHidden((prevState) => !prevState);
    };
    const customHeader = (
        <React.Fragment>
            <img src={logo_with_text} alt="Sakai Logo" height="45" className="mr-2 lg:mr-2" />
        </React.Fragment>
    );

    return (

        <>
            <div className="card flex justify-content-center">
                <Sidebar visible={visibleTop} position="top" onHide={() => setVisibleTop(false)} header={customHeader} style={{ height: "200px" }} >
                    <div className="custom-header">
                        <hr />
                    </div>
                    <div onClick={() => { navigate("/"); window.location.reload() }}>
                        <h5 style={{}}>Home</h5>
                    </div>
                    <div onClick={() => { navigate("/contactUs"); window.location.reload() }}>
                        <h5 style={{}}>Contact Us</h5>
                    </div>
                </Sidebar>
            </div>
            <div className="header py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between right lg:static">
                <div onClick={() => { navigate("/"); window.location.reload() }} className="flex align-items-center" style={{ maxWidth: "225px" }}>
                    <img src={logo_with_text} alt="Sakai Logo" height="45" className="mr-2 lg:mr-2" />
                </div>

                <div className="contact">
                    <a href="contactus" onClick={toggleMenuItemClick}>
                        <span className="text-700">Contact Us</span>
                    </a>
                </div>


                <div className='hamburger-menu'>
                    <i className="pi pi-bars text-4xl cursor-pointer block lg:hidden text-700" onClick={(e) => setVisibleTop(true)}></i>

                </div>


            </div>


            <div
                id="hero"
                className="flex flex-column pt-4 px-4 lg:px-6 overflow-hidden"
                style={{
                    backgroundColor: "#9E005D",
                    padding: "50px"
                }}
            >
                <div className="mx-4 md:mx-8 mt-0 md:mt-4 ">
                    <h1 className="text-5xl font-bold text-gray-900 line-height-2 centered" style={{ textAlign: "center", color: "white" }} class="white">
                        Mooruk Design Solutions
                    </h1>
                    <p className="font-normal text-xl line-height-4 md:mt-0 text-gray-700" style={{ textAlign: "center", fontSize: "20px" }} class="white">At Mooruk, we specialize in offering top-tier Physical Design Expertise, seamlessly guiding your projects from RTL to GDSII.</p>
                    <div className="flex justify-content-center">
                        <img src={img1} alt="Hero Image" className="w-10" />
                    </div>

                </div>

            </div>
            <div id="highlights" className="section2 py-4 px-4 lg:px-8 mx-0 my-0 lg:px-8">
                <div className="grid mt-4 pb-2 md:pb-4" >
                    <div className="col-12 lg:col-6 my-auto flex flex-column text-center lg:text-left lg:align-items-start">
                        <h2 className="line-height-1 text-900 text-4xl font-normal" style={{ color: "white" }} class="white">Experience</h2>
                        <span className="text-700 text-xl line-height-3 mr-0 md:mr-2" style={{ color: "white", fontSize: "20px" }} class="white">
                            Our team comprises Intermediate to Principal Level engineers, boasting an impressive combined total of over 140 years of expertise. We ensure that your projects receive the highest level of skill and expertise at every stage.
                        </span>
                    </div>

                    <div className="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0" style={{ borderRadius: '8px' }}>
                        <img style={{ marginTop: "20px" }} src={img2} className="w-12" alt="mockup" />
                    </div>
                </div>
            </div>
            <div id="highlights" className="section1 py-4 px-4 lg:px-8 mx-0 my-0 lg:px-8">
                <div className="grid mt-4 pb-2 md:pb-4">

                    <div className="flex justify-content-center col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0" style={{ borderRadius: '8px' }}>
                        <img src={img3} style={{ marginTop: "20px" }} className="w-12" alt="mockup mobile" />
                    </div>

                    <div className="col-12 lg:col-6 my-auto flex flex-column lg:align-items-end text-center lg:text-right">

                        <h2 className="line-height-1 text-900 text-4xl font-normal" style={{ color: "white" }} class="white">Versatility</h2>
                        <span className="text-700 text-xl line-height-3 ml-0 md:ml-2" style={{ color: "white", fontSize: "20px" }} class="white">
                            We take pride in our versatility, as we are proficient with both Synopsys and Cadence Place and Route tools, guaranteeing optimal results and efficiency.
                        </span>
                    </div>
                </div>
            </div>
            <div id="highlights" className="section2 py-4 px-4 lg:px-8 mx-0 my-0 lg:px-8">
                <div className="grid mt-4 pb-2 md:pb-4">
                    <div className="col-12 lg:col-6 my-auto flex flex-column text-center lg:text-left lg:align-items-start">
                        <h2 className="line-height-1 text-900 text-4xl font-normal" style={{ color: "white" }} class="white">Our Mission</h2>
                        <span className="text-700 text-xl line-height-3 mr-0 md:mr-2" style={{ color: "white", fontSize: "20px" }} class="white">
                            When you partner with Mooruk, you gain access to a wealth of experience, and a dedicated team committed to bringing your semiconductor designs to life with precision and excellence. Depend on us to be your trusted partner in Physical Design, where expertise meets innovation.
                        </span>
                    </div>

                    <div className="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0" style={{ borderRadius: '8px' }}>
                        <img src={img4} className="w-12" alt="mockup" style={{ marginTop: "20px" }} />
                    </div>
                </div>

            </div>

            <div className="py-4 px-4 mx-0 mt-8 lg:mx-8">
                <div className=" header grid" style={{ textAlign: "center" }}>
                    <div id="bottom" className="col-12 md:col-2" style={{ marginTop: '-1.5rem' }}>
                        <div style={{ height: "20px" }}>
                            <img src={require(`./images/logo.png`)} alt="footer sections" width="20" height="20" className="mr-1" />
                        </div>
                        <h4 className="font-medium text-xl line-height-1 text-900">MOORUK</h4>
                    </div>

                    <div className="col-12 md:col-3"></div>
                    <div id="bottom" className="col-12 md:col-2" style={{ marginTop: '-1.5rem' }}>

                        <h4 className="font-medium text-xl line-height-1 text-700" style={{ fontSize: "10px" }}>Copyrights &copy; 2023.</h4>
                        <h4 className="font-medium text-xl line-height-1 text-700">All Rights Reserved.</h4>
                    </div>
                    <div className="col-12 md:col-3"></div>
                    <div id="bottom" className="col-12 md:col-2" style={{ marginTop: '-1.5rem' }}>

                        <h4 className="font-medium text-xl line-height-1 text-700">+1 (613) 889-4979</h4>
                        <h4 className="font-medium text-xl line-height-1 text-700">
                            <a href="mailto:info@mooruk.com">info@mooruk.com</a>
                        </h4>

                    </div>
                </div>


            </div>

        </>


    );
}
